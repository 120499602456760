import ActionItem, { ActionItemTemplateKeys } from 'portal/models/action-item';
import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';
import { ActionItemMap } from '../action-items/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface ActionItemCompletionArgs {
    previousActivity: ActionItem;
    nextActivity: ActionItem;
}

export default class ActionItemCompletion extends Component<ActionItemCompletionArgs> {
    @service
        router!: RouterService;

    get viewDealRoute() {
        return 'protected.deal.index.to-do';
    }

    @action
    onNext() {
        if (!this.args.nextActivity.isDeleted) {
            this.args.nextActivity.hasStarted = true;
            this.args.nextActivity.save();
        }

        const actionItemTemplateKey = Object.entries(ActionItemTemplateKeys).find((a)=> a[1] === this.args.nextActivity.templateKey)?.[1];
        const route = actionItemTemplateKey ? ActionItemMap.get(actionItemTemplateKey)?.get('route') : false;
        if (!actionItemTemplateKey || !route){
            this.router.transitionTo('protected.deal');
            return;
        }
        if (ActionItemMap.get(actionItemTemplateKey)?.has('routeRequiresActivityId')){
            this.router.transitionTo(route, this.args.nextActivity.id);
        } else {
            this.router.transitionTo(route);
        }
    }

}
