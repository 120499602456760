import Deal from 'portal/models/deal';
import FeatureFlag from 'portal/services/feature-flag';
import Route from '@ember/routing/route';
import Transition from '@ember/routing/-private/transition';
import { maybeRedirectToNewPortalAfterModel, maybeRedirectToNewPortalBeforeModel } from 'portal/helpers/new-portal-redirect';
import { inject as service } from '@ember/service';

export default class BaseRoute extends Route {
    maybeRedirectToNewPortalAfterModel = maybeRedirectToNewPortalAfterModel;
    maybeRedirectToNewPortalBeforeModel = maybeRedirectToNewPortalBeforeModel;

    @service
    declare featureFlag: FeatureFlag;

    activate(): void {
        super.activate();
        window.scrollTo(0,0);
    }

    beforeModel(transition: Transition): any {
        return this.maybeRedirectToNewPortalBeforeModel(transition, this.featureFlag);
    }

    afterModel(model: unknown, transition: Transition): any {
        if (model instanceof Deal) {
            return this.maybeRedirectToNewPortalAfterModel(model, transition, this.featureFlag);
        }
    }
}
