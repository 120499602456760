import Component from '@glimmer/component';
import Deal from 'portal/models/deal';
import DealSide, { DealSides } from 'portal/services/deal-side';
import IndividualTitleholder from 'portal/models/individual-titleholder';
import Store from '@ember-data/store';
import Titleholder from 'portal/models/titleholder';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

interface DealIndividualMultiselectTitleholderArgs {
    titleholders: Titleholder[];
    initialTitleholder: IndividualTitleholder;
    deal: Deal;
    onNext: (titleholder: IndividualTitleholder) => void;
    onAdd: () => void;
}

export default class DealIndividualMultiselectTitleholder extends Component<DealIndividualMultiselectTitleholderArgs> {
    @service
    declare store: Store;

    @service
    declare dealSide: DealSide;

    @tracked
        showAtLeastOneTitleholderRequired = false;

    @action
    onTitleholderCheckChange(titleholder: IndividualTitleholder) {
        this.showAtLeastOneTitleholderRequired = false;
        titleholder.addToDealAsTitleholder = !titleholder.addToDealAsTitleholder;
        titleholder.inviteToDeal = false;
        titleholder.inviteSpouseToDeal = false;
    }

    onInvite(titleholder: IndividualTitleholder) {
        titleholder.inviteToDeal = !titleholder.inviteToDeal;
    }

    onInviteSpouse(titleholder: IndividualTitleholder) {
        titleholder.inviteSpouseToDeal = !titleholder.inviteSpouseToDeal;
    }

    @action
    onNext(titleholder: IndividualTitleholder) {
        const titleholdersToAdd = this.args.titleholders.filter((titleholder: IndividualTitleholder) => {
            return titleholder.addToDealAsTitleholder;
        });

        if (titleholdersToAdd.length === 0) {
            this.showAtLeastOneTitleholderRequired = true;
            return;
        }

        this.args.onNext(titleholder);
    }

    get title(): string {
        if (this.dealSide.for(this.args.deal.id) === DealSides.FUTURE) {
            return 'Select anyone else who should be listed on the title of the property.';
        } else {
            return 'Select anyone else who is listed on the title of the property.';
        }
    }

    get subtitle(): string {
        if (this.dealSide.for(this.args.deal.id) === DealSides.FUTURE) {
            return 'Add additional people who should be listed on the title of your home.';
        } else {
            return 'Add additional people who are listed on the title of your home.';
        }
    }
}