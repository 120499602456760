import AuthService from 'portal/services/auth';
import Component from '@glimmer/component';
import DealSide, { DealSides } from 'portal/services/deal-side';
import FeatureFlag from 'portal/services/feature-flag';
import IndividualTitleholder, {
    assignorIndividualTitleholderValidations,
    assignorIndividualTitleholderValidationsWithTaxId,
    individualTitleholderValidations,
    individualTitleholderValidationsWithTaxId
} from 'portal/models/individual-titleholder';
import LegalIndividual from 'portal/models/legal-individual';
import Store from '@ember-data/store';
import User from 'portal/models/user';
import { BufferedChangeset } from 'ember-changeset/types';
import { DealActorRoles } from 'portal/models/deal';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface DealIndividualTitleholderArgs {
    actorRole: string;
    user: User;
    dealId?: string;
    disabled?: boolean;
    titleholder: IndividualTitleholder | LegalIndividual;
    onNext?: (titleholderChangeset?: BufferedChangeset) => void;
    onCancel?: () => void;
}

export default class DealIndividualTitleholder extends Component<DealIndividualTitleholderArgs> {
    @service
    declare auth: AuthService;

    @service
    declare dealSide: DealSide;

    @service
    declare featureFlag: FeatureFlag;

    @service
    declare store: Store;

    @tracked
        titleholderChangeset?: BufferedChangeset;

    @tracked
        isUser: boolean;

    @tracked
        showErrorBanner?: boolean = false;

    constructor(owner: unknown, args: DealIndividualTitleholderArgs) {
        super(owner, args);

        this.isUser = args.user.email === args.titleholder.email;

        this.titleholderChangeset?.set('showErrorOnPristine', false);
    }

    get disabled() {
        return this.args.disabled || taskFor(this.onNext).isRunning;
    }

    get titleForDealRole() {
        return this.args.actorRole === DealActorRoles.SELLER ? 'Confirm the owner information.' : 'Confirm the titleholder information.';
    }

    @action
    isMarriedOnChange(value: boolean) {
        if (!this.titleholderChangeset) return;

        this.titleholderChangeset.isMarried = value;

        if (!value) {
            this.titleholderChangeset.set('spouseFirstName', null);
            this.titleholderChangeset.set('spouseMiddleName', null);
            this.titleholderChangeset.set('spouseLastName', null);
            this.titleholderChangeset.set('spouseSuffix', null);
            this.titleholderChangeset.set('spouseTaxId', null);
            this.titleholderChangeset.set('spouseEmail', null);
        }

        this.validateAndRegisterChangesets(this.titleholderChangeset);
    }

    @action
    validateAndRegisterChangesets(titleholderChangeset?: BufferedChangeset) {
        this.titleholderChangeset = titleholderChangeset;

        titleholderChangeset?.validate();
    }

    get titleholderFormValidations(): any {
        if (this.args.actorRole === DealActorRoles.ASSIGNOR) {
            if (this.args.dealId && this.dealSide.for(this.args.dealId) === DealSides.CURRENT) {
                return assignorIndividualTitleholderValidationsWithTaxId;
            }

            return assignorIndividualTitleholderValidations;
        }

        if (this.args.dealId && this.dealSide.for(this.args.dealId) === DealSides.CURRENT) {
            return individualTitleholderValidationsWithTaxId;
        }

        return individualTitleholderValidations;
    }

    @task
    async onNext() {
        const changeset = this.titleholderChangeset as BufferedChangeset;
        changeset.validate();

        if (changeset.isInvalid) {
            changeset.set('showErrorOnPristine', true);
            this.showErrorBanner = true;
            window.scrollTo(0, 0);
            return;
        }

        changeset.set('showErrorOnPristine', false);
        changeset.execute();

        if (this.args.onNext) {
            this.args.onNext(this.titleholderChangeset);
        }
    }
}
