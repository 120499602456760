import BaseRoute from 'portal/routes/base-route';
import ENV from 'portal/config/environment';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/-private/transition';
import config from 'portal/config/environment';
import { inject as service } from '@ember/service';

export default class S extends BaseRoute {
    @service
    declare router: RouterService;

    async beforeModel(transition: Transition) {
        const slug = transition.to.params.slug as string;

        const url = `${ENV.API_URL}/${ENV.API_NAMESPACE}/core/short_urls/${slug}`;

        const response = await fetch(url);

        if (!response.ok) {
            this.router.replaceWith(config.rootURL);
        }
        else {
            const data = await response.json();
            this.router.replaceWith(data.relative_url);
        }
    }
}
