import AuthService from 'portal/services/auth';
import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { teamInviteValidations } from 'portal/models/team-invite';
import { tracked } from '@glimmer/tracking';

export default class Protected extends Controller {
    @service
        auth!: AuthService;

    @service
        router!: RouterService;

    teamInviteValidations = teamInviteValidations;

    @tracked
        showTitleSearchFeesDialog?: boolean = false;

    @tracked
        showTeamInviteDialog?: boolean = true;

    @action
    hideTeamInviteDialog() {
        this.showTeamInviteDialog = false;
    }

    @action
    onCloseTitleSearchFeesDialog() {
        this.showTitleSearchFeesDialog = false;
    }

    @action
    shouldShowTitleSearchFeesDialog() {
        if (this.router.currentRouteName === 'protected.verify-email-address' || !this.auth.user) {
            this.showTitleSearchFeesDialog = false;
        } else {
            this.showTitleSearchFeesDialog = this.auth.user!.badStanding;
        }
    }

    get shouldShowTermsDialog() {
        if (this.router.currentRouteName === 'protected.verify-email-address') {
            return false;
        }

        if (!this.auth.user || this.auth.user?.acceptedTerms) {
            return false;
        }

        return !this.auth.isImpersonated();
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        protected: Protected;
    }
}
