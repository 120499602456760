import Analytics from 'portal/services/analytics';
import Component from '@glimmer/component';
import User, { userInvoiceEmailValidations } from 'portal/models/user';
import lookupValidator from 'ember-changeset-validations';
import { BufferedChangeset } from 'ember-changeset/types';
import { Changeset } from 'ember-changeset';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface TitleSearchFeesDialogArgs {
    onCancel: () => void;
    user: User;
}

export default class TitleSearchFeesDialog extends Component<TitleSearchFeesDialogArgs> {
    @service
    declare analytics: Analytics;

    changeset: BufferedChangeset;

    constructor(owner: unknown, args: TitleSearchFeesDialogArgs) {
        super(owner, args);
        this.args.user.invoiceEmail = '';
        this.changeset = Changeset(this.args.user, lookupValidator(userInvoiceEmailValidations), userInvoiceEmailValidations);
    }

    @action onCancel() {
        this.analytics.trackEvent('Title Search Fees Dialog - Cancel Clicked');
        this.args.onCancel();
    }

    @action
    async onSave() {
        this.changeset.set('showErrorOnPristine', true);

        if (this.changeset.isInvalid) {
            return;
        }

        await this.changeset.save();
        this.analytics.trackEvent('Title Search Fees Dialog - Invoice Email Saved');
        this.args.onCancel();
    }

    @action
    validate() {
        this.changeset.validate();
    }
}
